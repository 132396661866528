<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div class="flex flex-wrap align-items marginBottom20">
          <div class="flex align-items marginRight10 marginBottom10">
            <div class="width300">
              <el-input
                v-model="searchData.name"
                placeholder="输入公司抬头/姓名/联系电话"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="20"
              ></el-input>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <div class="width150">
              <el-select
                class="width250"
                v-model="searchData.type"
                clearable
                @change="handleSearch"
              >
                <el-option
                  value=""
                  label="请选择类型"
                ></el-option>
                <el-option
                  :value="2"
                  label="个人"
                ></el-option>
                <el-option
                  :value="1"
                  label="公司"
                ></el-option>
              </el-select>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <div class="width200">
              <el-select
                class="width250"
                v-model="searchData.state"
                clearable
                @change="handleSearch"
              >
                <el-option
                  value=""
                  label="请选择状态"
                ></el-option>
                <el-option
                  :value="1"
                  label="审批中"
                ></el-option>
                <el-option
                  :value="2"
                  label="已通过"
                ></el-option>
                <el-option
                  :value="3"
                  label="已驳回"
                ></el-option>
              </el-select>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              class="marginLeft10"
            >查询</el-button>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              size="medium"
              type="info"
              icon="el-icon-refresh-right"
              @click="handleReset"
              class="marginLeft10"
            >重置</el-button>
          </div>
        </div>
      </template>
      <template
        slot="workCertImages"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          v-if="row.workCertImages"
          @click="onPreview(row)"
        >查看</el-button>
        <el-image
          style="width: 0; height: 0; "
          ref="preview"
          :src="imageList[0]"
          :preview-src-list="imageList"
          v-if="row.workCertImages"
        ></el-image>
        <div v-if="!row.workCertImages">/</div>
      </template>

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <div v-if="row.state === 1">
          <el-button
            type="text"
            size="mini"
            @click="handleAgree(row)"
          >通过</el-button>
          <el-button
            type="text"
            size="mini"
            @click="handleReject(row)"
          >驳回</el-button>
        </div>
        <div v-else>/</div>
      </template>
    </avue-crud>

    <!-- 通过弹框 -->
    <el-dialog
      v-if="dialogVisibleTg"
      :title="'通过'"
      :visible.sync="dialogVisibleTg"
      width="500px"
      :before-close="handleCloseTg"
    >
      <avue-form
        ref="formTg"
        v-model="formTg"
        :option="formOptionTg"
        @submit="submitTg"
      >
        <template slot="typeId">
          <el-select
            v-model="formTg.typeId"
            placeholder="请选择类型"
            @change="getcarTypeListHandle"
          >
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>
        <template slot="couponId">
          <el-select
            v-model="formTg.couponId"
            placeholder="请选择优惠券"
          >
            <el-option
              v-for="item in couponList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>
        <template slot="retailScale">
          <el-input-number
            v-model="formTg.retailScale"
            :disabled="disabled"
            style="width:320px !important"
            placeholder="请输入分销默认比例"
            :precision="1"
            :max="100"
            :min="0"
            :controls="false"
          ></el-input-number>
        </template>
        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleCloseTg"
          >取消</el-button>
        </template>
      </avue-form>
    </el-dialog>

    <!-- 驳回弹框 -->
    <el-dialog
      v-if="dialogVisibleBh"
      :title="'驳回'"
      :visible.sync="dialogVisibleBh"
      width="500px"
      :before-close="handleCloseBh"
    >
      <avue-form
        ref="formBh"
        v-model="formBh"
        :option="formOptionBh"
        @submit="submitBh"
      >
        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleCloseBh"
          >取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  examineList,
  examineTypeList,
  examineTypeCouponList,
  examineAgree,
  examineReject,
} from "@/api/partner/withdraw";
export default {
  name: "index",
  data() {
    return {
      imageList: [],
      disabled: false,
      searchData: {
        name: "",
        type: "",
        state: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "销售合伙人资料审核",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        menu: true,
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "类型",
            prop: "type",
            formatter: function (row, column, cellValue) {
              return  row.type  === 1
                ? "公司"
                :  row.type  === 2
                ? "个人"
                :  row.type ;
            },
          },
          {
            label: "公司抬头",
            prop: "companyTitle",
            formatter: function (row, column, cellValue) {
              return cellValue ? cellValue : "/";
            },
          },
          {
            label: "税号",
            prop: "dutyParagraph",
            formatter: function (row, column, cellValue) {
              return cellValue ? cellValue : "/";
            },
          },
          {
            label: "联系电话",
            prop: "phone",
          },
          {
            label: "邮箱",
            prop: "email",
          },
          {
            label: "公司地址",
            prop: "address",
            formatter: function (row, column, cellValue) {
              return cellValue ? cellValue : "/";
            },
          },
          {
            label: "收款账号",
            prop: "gatherPriceAccount",
          },
          {
            label: "姓名",
            prop: "username",
            formatter: function (row, column, cellValue) {
              return cellValue ? cellValue : "/";
            },
          },
          {
            label: "身份证号",
            prop: "idCard",
            formatter: function (row, column, cellValue) {
              return cellValue ? cellValue : "/";
            },
          },
          {
            label: "介绍人姓名",
            prop: "introducer",
            formatter: function (row, column, cellValue) {
              return cellValue ? cellValue : "/";
            },
          },
          {
            label: "介绍人手机号",
            prop: "introducerPhone",
            formatter: function (row, column, cellValue) {
              return cellValue ? cellValue : "/";
            },
          },
          {
            label: "职业证明",
            prop: "workCertImages",
          },
          {
            label: "状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state === 1
                ? "审批中"
                : row.state === 2
                ? "已通过"
                : row.state === 3
                ? "已驳回"
                : row.state;
            },
          },
          {
            label: "申请时间",
            prop: "createTime",
          },
          {
            label: "驳回原因",
            prop: "rejectReason",
            formatter: function (row, column, cellValue) {
              return cellValue ? cellValue : "/";
            },
          },
        ],
      },

      // 通过表单
      dialogVisibleTg: false,
      couponList: [],
      typeList: [],
      formTg: {
        id: "",
        couponId: "",
        typeId: "",
        retailScale: 10,
      },
      formOptionTg: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "130",
        column: [
          {
            label: "类型",
            prop: "typeId",
            type: "select",
            span: 24,
            row: true,
            rules: [
              {
                required: true,
                message: "请选择类型",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value === "") {
                    callback(new Error("请选择类型"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "分销默认比例",
            prop: "retailScale",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入分销默认比例",
                trigger: "blur",
              },
            ],
          },
          {
            label: "关联优惠券",
            prop: "couponId",
            type: "select",
            span: 24,
            row: true,
            rules: [
              {
                required: true,
                message: "请选择优惠券",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value === "") {
                    callback(new Error("请选择优惠券"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
        ],
      },

      // 驳回表单
      dialogVisibleBh: false,
      formBh: {
        id: "",
        rejectReason: "暂不符合申请条件",
      },
      formOptionBh: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: 0,
        column: [
          {
            prop: "rejectReason",
            type: "textarea",
            size: "medium",
            minRows: 3,
            span: 24,
            placeholder: "请输入驳回原因",
            maxRows: 10,
            maxlength: 100,
            rules: [
              {
                required: true,
                message: "请输入驳回原因",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    getcarTypeListHandle(e) {
      this.typeList.map((item) => {
        if (e === item.id) {
          this.formTg.retailScale = item.retailScale;
          this.formTg.couponId = item.couponId;
        }
      });
    },
    // 类型列表
    examineTypeList() {
      examineTypeList({}).then((res) => {
        if (res.code === 200) {
          this.typeList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 优惠券列表
    examineTypeCouponList() {
      this.couponList = [];
      examineTypeCouponList({}).then((res) => {
        if (res.code === 200) {
          this.couponList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 通过
    handleAgree(row) {
      this.dialogVisibleTg = true;
      this.formTg = {
        ...this.formTg,
        id: row.id,
      };
      this.examineTypeList();
      this.examineTypeCouponList();
    },
    // 通过关闭
    handleCloseTg() {
      this.dialogVisibleTg = false;
      setTimeout(() => {
        this.resetFormTg();
      }, 500);
    },
    // 重置通过表单
    resetFormTg() {
      this.formTg = {
        id: "",
        couponId: "",
        typeId: "",
        retailScale: 10,
      };
    },
    // 通过确定
    submitTg(form, done) {
      examineAgree(form)
        .then((res) => {
          if (res.code === 200) {
            this.dialogVisibleTg = false;
            setTimeout(() => {
              this.resetFormTg();
            }, 500);
            this.$message.success("操作成功");
            this.onLoad();
            done();
          } else {
            this.$message.error(res.msg);
            done();
          }
        })
        .catch(() => {
          done();
        });
    },

    // 驳回
    handleReject(row) {
      this.dialogVisibleBh = true;
      this.formBh = {
        id: row.id,
        rejectReason: "暂不符合申请条件",
      };
    },
    // 驳回关闭
    handleCloseBh() {
      this.dialogVisibleBh = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置驳回表单
    resetForm() {
      this.formBh = {
        id: "",
        rejectReason: "暂不符合申请条件",
      };
    },
    // 驳回确定
    submitBh(form, done) {
      examineReject(form)
        .then((res) => {
          if (res.code === 200) {
            this.dialogVisibleBh = false;
            setTimeout(() => {
              this.resetForm();
            }, 500);
            this.$message.success("操作成功");
            this.onLoad();
            done();
          } else {
            this.$message.error(res.msg);
            done();
          }
        })
        .catch(() => {
          done();
        });
    },
    // 预览大图
    onPreview(row) {
      this.imageList = row.workCertImages.split(",");
      this.$nextTick(() => {
        this.$refs.preview.clickHandler();
      });
    },
    // 查询
    handleSearch() {
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      this.searchData = {
        name: "",
        type: "",
        state: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify({ ...searchForm }));
      this.showLoading = true;
      examineList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
  },
};
</script>
